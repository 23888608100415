import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto otp generate`}</strong>{` -- generate a one-time password`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto otp generate [--issuer=<name>]
[--account=<user-name>] [--period=<seconds>] [--length=<size>]
[--alg=<alg>] [--url] [--qr]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto otp generate`}</strong>{` does TOTP and HTOP`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--iss`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Name of the issuing organization (e.g., smallstep.com)`}</p>
    <p><strong parentName="p">{`--account`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Name of the user's account (e.g., a username or email
address)`}</p>
    <p><strong parentName="p">{`--period`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Number of seconds a TOTP hash is valid. Defaults to 30
seconds.`}</p>
    <p><strong parentName="p">{`--length`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--digits`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Length of one-time passwords. Defaults to 6.`}</p>
    <p><strong parentName="p">{`--secret-size`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Size of generated TOTP secret. Defaults to 20.`}</p>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Algorithm to use for HMAC. Defaults to SHA1. Must be
one of: SHA1, SHA256, SHA512`}</p>
    <p><strong parentName="p">{`--url`}</strong>{`
Output a TOTP Key URI. See
`}<a parentName="p" {...{
        "href": "https://github.com/google/google-authenticator/wiki/Key-Uri-Format"
      }}>{`https://github.com/google/google-authenticator/wiki/Key-Uri-Format`}</a></p>
    <p><strong parentName="p">{`--qr`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Write a QR code to the specified path`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      